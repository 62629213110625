































































































































































































































import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import Loading from "@/components/Loading.vue";
import * as humanizeDistance from "humanize-distance";
import "intl/locale-data/jsonp/en-US";
import * as openGeocoder from "node-open-geocoder";

export default Vue.extend({
  components: { Loading },
  data() {
    return {
      self: this,
      userManager,
      nearByChargingLocations: [],
      latestChargingSessions: [],
      point: 0,
      initializeLoading: false,
      locationLoading: false,
      position: null,
      address: "",
    };
  },
  methods: {
    async initialize() {
      this.initializeLoading = true;
      const promises = [];
      const self = this;
      if (!userManager.isGuest()) {
        promises.push(
          (async () => {
            const { point } = await coreApiClient.call("users", "findById", {
              id: userManager.getUserInfo()._id,
            });
            self.point = point || 0;
          })()
        );
      }
      if (!userManager.isGuest()) {
        promises.push(
          (async () => {
            const filters = [
              {
                key: "stopTime",
                operator: "greater_than",
                value: 0,
              },
            ];

            if (userManager.checkRole(["cpoAdmin", "cpoMonitor", "retailer"], true, true)) {
              filters.push({
                key: "tenantId",
                operator: "equal_to",
                value: userManager.getUserInfo().tenantId,
              });
            }

            const { items } = await coreApiClient.call(
              "chargingSessions",
              "findAll",
              {
                payload: JSON.stringify({
                  limit: 3,
                  orderBy: "startTime",
                  orderType: "desc",
                  filters,
                }),
              }
            );
            self.latestChargingSessions = items;
          })()
        );
      }
      self.initializePosition();
      await Promise.all(promises);
      this.initializeLoading = false;
    },
    async initializePosition() {
      {
        try {
          this.locationLoading = true;
          const position = await this.getLocation();
          this.position = position;
          {
            const response: any = await new Promise((resolve, reject) => {
              openGeocoder()
                .reverse(position.coords.longitude, position.coords.latitude)
                .end((err, res: any) => {
                  if (err) {
                    reject(err);
                  } else {
                    resolve(res);
                  }
                });
            });
            this.address = response.display_name;
          }
        } catch (e) {
          console.log(e);
        } finally {
          this.locationLoading = false;
        }
      }
      if (this.position) {
        const filters = [
          {
            key: "location",
            operator: "near_sphere",
            value: {
              lat: this.position.coords.latitude,
              lng: this.position.coords.longitude,
              maxDistance: 10000,
            },
          },
        ];
        if (userManager.checkRole(["cpoAdmin", "cpoMonitor", "retailer"], true, true)) {
          filters.push({
            key: "tenantId",
            operator: "equal_to",
            value: userManager.getUserInfo().tenantId,
          });
        }

        const { items } = await coreApiClient.call(
          "chargingLocations",
          "findAll",
          {
            payload: JSON.stringify({
              limit: -1,
              filters
            }),
          }
        );
        this.nearByChargingLocations = items;
      }
    },
    async getLocation() {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            resolve(position);
          }, reject);
        } else {
          reject(new Error("Geolocation is not supported by this browser."));
        }
      });
    },
    distanceToCurrentLocation(lat, lng) {
      if (!this.position || isNaN(lat) || isNaN(lng)) {
        return "N/A";
      }
      const a = {
        latitude: this.position.coords.latitude,
        longitude: this.position.coords.longitude,
      };
      const b = { latitude: lat, longitude: lng };
      return humanizeDistance(a, b);
    },
  },
  async created() {
    this.initialize();
  },
});
